<template>
  <div class="row">
    <div class="flex xs12">
      <table
        class="va-table va-table--striped va-table--hoverable table-card"
        style="width: 100%;"
      >
        <tbody>
          <text-row
            v-if="report.team"
            :title="$t('tables.headings.team')"
            size="50"
          >
            {{ report.team.code }}
          </text-row>
        </tbody>
      </table>
    </div>
    <div
      class="flex xs12"
      v-if="isArray(report.presentations)"
    >
      <div
        v-for="presentation of report.presentations"
        :key="presentation.id"
      >
        <table class="va-table va-table--striped va-table--hoverable table-card">
          <tbody>
            <text-row
              :title="$t('reports.modal.inputs.monthInput')"
              size="50"
            >
              {{ presentation.presentation_date | date }}
            </text-row>
            <text-row
              :title="$t('tables.headings.images_count')"
              size="50"
            >
              {{ presentation.presentation_images.length }}
            </text-row>
          </tbody>
        </table>
        <carousel v-if="presentation.presentation_images.length > 0">
          <slide
            v-for="image of presentation.presentation_images"
            :key="image.id"
          >
            <img
              class="gallery-image"
              width="120px"
              v-lazy="getPath(image.path)"
              @click="showLightbox(image.path, presentation.id)"
            />
          </slide>
        </carousel>
      </div>
    </div>
    <div
      class="flex xs12"
      v-else
    >
      <span>{{$t('layout.empty')}}</span>
    </div>
    <light-box
      ref="lightbox"
      :show-caption="true"
      :show-light-box="false"
      :show-thumbs="false"
      :media="filteredImages"
    >
    </light-box>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
const TextRow = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextRow')
const LightBox = () => import(/* webpackPrefetch: true */ 'vue-image-lightbox')

export default {
  name: 'presentation-images',
  components: {
    LightBox,
    Carousel,
    TextRow,
    Slide,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      images: [],
      galleryFilter: null,
    }
  },
  computed: {
    filteredImages: function () {
      if (this.galleryFilter === 'all') {
        return this.images
      } else {
        return this.images.filter(image => image.filter === this.galleryFilter)
      }
    },
  },
  watch: {
    report (val) {
      this.setImages(val)
    },
  },
  methods: {
    isArray (property) {
      return Array.isArray(property)
    },
    getPath (url) {
      let base = this.$http.defaults.baseURL.slice(0)
      if (base) {
        if (/\/api\//g.test(base)) {
          base = base.replace('/api/', '')
        }
        if (!/^https?:\/\/[^/]+/g.test(base)) {
          base = window.origin + base
        }
        url = base + url
      }

      return url
    },
    getAlt (team, date) {
      return `${this.$t('tables.headings.team')} ${team}. ${this.$t('tables.headings.date')}: ${date}`
    },
    setImages (report) {
      if (!report) {
        return
      }

      const images = []
      for (const presentation of report.presentations) {
        const date = this.$date.format(presentation.presentation_date, 'dd/MM/yyyy')
        for (const image of presentation.presentation_images) {
          const i = {
            id: image.id,
            src: this.getPath(image.path),
            caption: this.getAlt(report.team.code, date),
            filter: presentation.id.toString(),
          }

          images.push(i)
        }
      }

      this.images = images.slice(0)
    },
    showLightbox: function (path, filter) {
      this.galleryFilter = filter.toString()
      path = this.getPath(path)

      const index = this.filteredImages.findIndex(i => i.src === path)
      this.$refs.lightbox.showImage(index)
    },
  },
}
</script>

<style scoped>
.gallery-image {
  cursor: pointer;
}
</style>
